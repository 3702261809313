import React from "react";
import Skill from "./Skill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";

const Skills = () => {
  return (
    <div id="Skills" className="m-8 flex flex-col items-center ">
      <div className="flex flex-col items-center max-w-2xl m-8">
        <FontAwesomeIcon
          icon={faMicrochip}
          className="text-5xl text-cyan-400"
        />
        <h2 className="dark:text-white text-center text-5xl my-4 font-semibold">
          Skills & Technologies
        </h2>
        <p className="dark:text-gray-400 font-semibold text-center">
          This is a list of some of the skills & technologies that I have worked with in the past. Some I have extensive knowledge of and some I am only familiar with. I'm interested in learning new technologies if required.
        </p>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <Skill Name="Java" />
        <Skill Name="Python" />
        <Skill Name="React" />
        <Skill Name="Tailwind CSS" />
        <Skill Name="C#" />
        <Skill Name="C++" />
        <Skill Name="JavaScript" />
        <Skill Name="SQL" />
        <Skill Name="Git" />
        <Skill Name="Unity" />
      </div>
    </div>
  );
};

export default Skills;
