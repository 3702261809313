import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const squareLink = ({ Icon, Link, LinkDescription }) => {
  return (
    <a
      aria-label={LinkDescription}
      target="_blank"
      href={Link}
      rel="noreferrer"
      className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 border-black dark:border-white hover:border-transparent dark:hover:border-transparent hover:bg-orange-600 dark:hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
    >
      <FontAwesomeIcon
        icon={Icon}
        className="group-hover/button:rotate-[135deg] duration-500"
      />
    </a>
  );
};

export default squareLink;
