import React from "react";
import Module from "./Module";
import CompVis from "../images/ComputerVision.png";
import WhiteMask from "../images/WhiteMask.png";
import AdvPro from "../images/AdvancedProgramming.png";
import CPP from "../images/CPP.png";
import CompSec from "../images/ComputerSecurity.png";
import AppPro from "../images/ApplicationProgramming.png";
import DataStr from "../images/DataStructuresAndAlgorithms.png";
import GamePro from "../images/GameProgramming.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";

const Modules = () => {
  return (
    <div id="Modules" className=" relative mx-8 flex flex-col items-center ">
      <div className="flex flex-col items-center max-w-2xl m-8">
        <FontAwesomeIcon
          icon={faBuildingColumns}
          className="text-5xl text-cyan-400"
        />
        <h2 className="dark:text-white text-center text-5xl my-4 font-semibold">
          University Modules
        </h2>
        <p className="dark:text-gray-400 font-semibold text-center">
          Here are some of the module courseworks and projects that I have
          worked on during my time at university.
        </p>
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
        <Module
          ModuleName="Year 3 Capstone"
          ModuleTech="C#, Unity, AI"
          ModuleDescriptionP1="The Capstone project placed a high emphasis on the significance of Artificial Intelligence (AI) in gaming. The primary goal was to create dynamic and intricate AI systems that challenge the player to respond uniquely to each scenario."
          ModuleDescriptionP2="The project utilised the A* search algorithm to develop and optimise pathfinding and decision-making processes for hostile game entites."
          ModuleDescriptionP3="The project took form of a Metroidvania-like game and was developed using Unity with C#, the project was inspired by the existing title ''Hollow Knight''"
          ModuleImage={WhiteMask}
          ModuleLink=""
        />
        <Module
          ModuleName="Computer Vision"
          ModuleTech="Python, OpenCV"
          ModuleDescriptionP1="Designed and developed a program using Python and OpenCV to analyse computer-generated asteroid images from two cameras."
          ModuleDescriptionP2="Implemented mathematical equations to estimate the distance of objects in each image, enabling more precise analysis of the asteroid's movement."
          ModuleDescriptionP3="Developed custom algorithms to analyse each image frame and identify asteroid flight paths, flagging any potential anomalies as potential UFO sightings for further investigation."
          ModuleImage={CompVis}
          ModuleLink="https://github.com/Kami2Kc/Computer-Vision"
        />
        <Module
          ModuleName="Advanced Programming"
          ModuleTech="Java, C#"
          ModuleDescriptionP1="Created a cross-language trading software solution that enabled traders to exchange resources seamlessly regardless of the client software language used."
          ModuleDescriptionP2="Utilised Java and C# programming languages to build the software solution, ensuring its compatibility with a wide range of platforms."
          ModuleDescriptionP3="Developed a robust, multi-threaded server system to handle each client connection separately, maximising scalability and improving overall system performance."
          ModuleImage={AdvPro}
          ModuleLink="https://github.com/Kami2Kc/Advanced-Programming"
        />
        <Module
          ModuleName="Application Programming"
          ModuleTech="Java, Swing"
          ModuleDescriptionP1="Re-created the classic Retro game Space-Invaders."
          ModuleDescriptionP2="Developed a fully functional game with smooth gameplay and engaging user experience."
          ModuleDescriptionP3="Implemented a high-score feature that allowed players to import scores from a file, adding an extra layer of competition and engagement."
          ModuleImage={AppPro}
          ModuleLink="https://github.com/Kami2Kc/Application-Programming"
        />
        <Module
          ModuleName="Computer Security"
          ModuleTech="Python"
          ModuleDescriptionP1="Studied steganography and other computer security concepts, including code injection techniques."
          ModuleDescriptionP2="Applied practical skills by extracting hidden texts from image files and performing code injection exercises."
          ModuleDescriptionP3={null}
          ModuleImage={CompSec}
          ModuleLink="https://github.com/Kami2Kc/Computer-Security"
        />
        <Module
          ModuleName="Data Structures and Algorithms"
          ModuleTech="Java"
          ModuleDescriptionP1="Studied a variety of data structures, including deques, and their applications in software development."
          ModuleDescriptionP2="Developed an algorithm that can process algebraic equations and generate results based on input values."
          ModuleDescriptionP3=""
          ModuleImage={DataStr}
          ModuleLink="https://github.com/Kami2Kc/Data-Structures-and-Algorithms"
        />
        <Module
          ModuleName="Game Programming"
          ModuleTech="Java, Swing"
          ModuleDescriptionP1="Re-created the classic Retro game Asteroids using Java Swing."
          ModuleDescriptionP2="This assignment focused on utilizing OOP principles to the fullest extent, and also incorporated sound unlike the Space-Invaders game."
          ModuleDescriptionP3="Also featured a high-score system to store use scores."
          ModuleImage={GamePro}
          ModuleLink="https://github.com/Kami2Kc/Game-Programming"
        />
      </div>
    </div>
  );
};

export default Modules;
