import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleHalfStroke } from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import "aos/dist/aos.css";

const Dropdown = ({ open, setOpen, darkMode, setDarkMode }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="fixed bg-white h-screen w-1/4 right-0 dark:text-white font-semibold flex flex-col items-center text-center justify-evenly text-xl duration-500">
      <div className="-z-10 absolute inset-0 bg-black -translate-y-full dark:translate-y-0 duration-1000"/>
      <a
        className="px-3 select-none"
        href="#Modules"
        onClick={() => {
          setOpen(false);
        }}
      >
        University Modules
      </a>
      <a
        className="px-3 select-none"
        href="#Projects"
        onClick={() => {
          setOpen(false);
        }}
      >
        Personal Projects
      </a>
      <a
        className="px-3 select-none"
        href="#Skills"
        onClick={() => {
          setOpen(false);
        }}
      >
        Skills
      </a>
      <a
        className="px-3 select-none"
        href="#Contact"
        onClick={() => {
          setOpen(false);
        }}
      >
        Contact
      </a>
      <button
        className="flex items-center justify-center text-3xl w-10 h-10 rotate-180 dark:rotate-0 duration-500"
        onClick={() => {
          setDarkMode(!darkMode);
        }}
      >
        <FontAwesomeIcon icon={faCircleHalfStroke} />
      </button>
    </div>
  );
};

export default Dropdown;
