import React from "react";
import cv from "../cv/Kamil_M_Cwigun_CV.pdf";
import SquareLink from "../UI/squareLink";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-black px-6 py-6 flex flex-col justify-center items-center duration-500">
      <div className="text-md dark:text-white font-semibold items-center flex justify-between w-full lg:w-1/2">
        <SquareLink Icon={faFileLines} Link={cv} LinkDescription={"CV"} />
        <SquareLink
          Icon={faEnvelope}
          Link={"mailto:kamiwgun@googlemail.com"}
          LinkDescription={"Email"}
        />
        <SquareLink
          Icon={faLinkedin}
          Link={"https://www.linkedin.com/in/kamil-cwigun-b19470240/"}
          LinkDescription={"LinkedIn"}
        />
        <SquareLink
          Icon={faGithub}
          Link={"https://github.com/Kami2Kc"}
          LinkDescription={"GitHub"}
        />
        <SquareLink
          Icon={faDiscord}
          Link={"https://discordapp.com/users/171017679136751617"}
          LinkDescription={"Discord"}
        />
        <SquareLink
          Icon={faInstagram}
          Link={"https://www.instagram.com/kami_2kc/"}
          LinkDescription={"Instagram"}
        />
        <SquareLink
          Icon={faTwitter}
          Link={"https://twitter.com/Kami_2K"}
          LinkDescription={"Twitter"}
        />
      </div>

      <div className="w-full h-px bg-black dark:bg-white my-6" />
      <div className="block text-sm dark:text-white text-center">
        <p> Copyright © 2023 Kamil Cwigun.</p>
      </div>
    </footer>
  );
};

export default Footer;
