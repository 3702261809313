import React from "react";
import Dropdown from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleHalfStroke } from "@fortawesome/free-solid-svg-icons";

const NavBar = ({ open, setOpen, darkMode, setDarkMode }) => {
  return (
    <>
      <nav
        className={`z-10 absolute top-0 left-0 right-0 font-semibold flex justify-between items-center h-16 drop-shadow-sm font-mono bg-black duration-1000 translate-x-full dark:translate-x-0`}
        role="navigation"
      >
        <div className="pl-4 drop-shadow-lg items-center flex">
          <div
            className="p-3 text-xl text-white cursor-default select-none"
            to="/"
          >
            Kamil Cwigun <p className="font-thin text-sm">V 3.0</p>
          </div>
        </div>

        <div className="p-8 cursor-pointer flex sm:hidden text-white">
          <button
            className={`absolute group right-8 top-[12px] h-10 w-10 py-1 ${
              open && ""
            } duration-500 overflow-show`}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div
              className={`relative flex flex-col justify-center items-center ${
                open && "rotate-[360deg]"
              } duration-500`}
            >
              <div className="absolute h-10 w-10 flex flex-col justify-between items-center py-1">
                <div
                  className={`h-1 w-10 bg-white ${
                    open && "opacity-0"
                  } duration-500`}
                />
                <div
                  className={`h-1 w-10 bg-white ${
                    open && "opacity-0"
                  } duration-500`}
                />
                <div
                  className={`h-1 w-10 bg-white ${
                    open && "opacity-0"
                  } duration-500`}
                />
              </div>
              <div
                className={`absolute h-1 w-10 bg-white opacity-0 ${
                  open && "opacity-100"
                } rotate-45 duration-300`}
              />
              <div
                className={`absolute h-1 w-10 bg-white opacity-0 ${
                  open && "opacity-100"
                } -rotate-45 duration-300`}
              />
            </div>
          </button>
        </div>
        <div className="pr-8 space-x-2 drop-shadow-xl text-xs md:text-base lg:text-lg text-white hidden sm:flex">
          <a
            className="px-3 font-semibold hover-underline-effect active:text-neutral-300 duration-100 select-none"
            href="#Modules"
          >
            University Modules
          </a>
          <p>|</p>
          <a
            className="px-3 font-semibold hover-underline-effect active:text-neutral-300 duration-100 select-none"
            href="#Projects"
          >
            Personal Projects
          </a>
          <p>|</p>
          <a
            className="px-3 font-semibold hover-underline-effect active:text-neutral-300 duration-100 select-none"
            href="#Skills"
          >
            Skills
          </a>
          <p>|</p>
          <a
            className="px-3 font-semibold hover-underline-effect active:text-neutral-300 duration-100 select-none"
            href="#Contact"
          >
            Contact
          </a>
          <p>|</p>
          <button
            className="flex items-center"
            onClick={() => {
              setDarkMode(!darkMode);
            }}
          >
            <FontAwesomeIcon icon={faCircleHalfStroke} />
          </button>
        </div>
      </nav>
      <nav
        className={`z-10 absolute top-0 left-0 right-0 font-semibold flex justify-between items-center h-16 drop-shadow-sm font-mono bg-white duration-1000 -translate-x-0 dark:-translate-x-full`}
        role="navigation"
      >
        <div className="pl-4 drop-shadow-lg items-center flex">
          <div
            className="p-3 text-xl dark:text-white cursor-default select-none"
            to="/"
          >
            Kamil Cwigun <p className="font-thin text-sm">V 3.0</p>
          </div>
        </div>

        <div className="p-8 cursor-pointer flex sm:hidden text-white">
          <button
            className={`absolute group right-8 top-[12px] h-10 w-10 py-1 ${
              open && ""
            } duration-500 overflow-show`}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div
              className={`relative flex flex-col justify-center items-center ${
                open && "rotate-[360deg]"
              } duration-500`}
            >
              <div className="absolute h-10 w-10 flex flex-col justify-between items-center py-1">
                <div
                  className={`h-1 w-10 bg-black ${
                    open && "opacity-0"
                  } duration-500`}
                />
                <div
                  className={`h-1 w-10 bg-black ${
                    open && "opacity-0"
                  } duration-500`}
                />
                <div
                  className={`h-1 w-10 bg-black ${
                    open && "opacity-0"
                  } duration-500`}
                />
              </div>
              <div
                className={`absolute h-1 w-10 bg-black opacity-0 ${
                  open && "opacity-100"
                } rotate-45 duration-300`}
              />
              <div
                className={`absolute h-1 w-10 bg-black opacity-0 ${
                  open && "opacity-100"
                } -rotate-45 duration-300`}
              />
            </div>
          </button>
        </div>
        <div className="pr-8 space-x-2 drop-shadow-xl text-xs md:text-base lg:text-lg hidden sm:flex">
          <a
            className="px-3 font-semibold hover-underline-effect-black active:text-neutral-300 duration-100 select-none"
            href="#Modules"
          >
            University Modules
          </a>
          <p>|</p>
          <a
            className="px-3 font-semibold hover-underline-effect-black active:text-neutral-300 duration-100 select-none"
            href="#Projects"
          >
            Personal Projects
          </a>
          <p>|</p>
          <a
            className="px-3 font-semibold hover-underline-effect-black active:text-neutral-300 duration-100 select-none"
            href="#Skills"
          >
            Skills
          </a>
          <p>|</p>
          <a
            className="px-3 font-semibold hover-underline-effect-black active:text-neutral-300 duration-100 select-none"
            href="#Contact"
          >
            Contact
          </a>
          <p>|</p>
          <button
            className="flex items-center rotate-180"
            onClick={() => {
              setDarkMode(!darkMode);
              console.log(darkMode);
            }}
          >
            <FontAwesomeIcon icon={faCircleHalfStroke} />
          </button>
        </div>
      </nav>
      <div
        className={`absolute inset-0 -translate-y-full ${
          open && "!translate-y-0"
        } duration-1000`}
      >
        <Dropdown
          setOpen={setOpen}
          open={open}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
        />
      </div>
    </>
  );
};

export default NavBar;
