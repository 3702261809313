import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const Project = ({
  ProjectName,
  ProjectTools,
  ProjectDescription,
  ProjectDisclaimer,
  ProjectImage,
  ProjectImageDescription,
  ProjectLink,
  ProjectGithub,
}) => {
  return (
    <div className="relative h-[500px] sm:h-auto group/main hover:scale-105 duration-500 overflow-hidden max-w-3xl">
      <img
        src={ProjectImage}
        alt={ProjectImageDescription}
        className="object-cover h-full sm:h-auto scale-[120%] group-hover/main:scale-100 group-hover/main:blur-sm duration-500"
      />

      <div className="absolute bg-zinc-800 items-center inset-0 bg-opacity-50 opacity-0 group-hover/main:opacity-100 duration-500">
        <div className="p-16 text-white inset-0 absolute justify-center flex flex-col -bottom-full top-full group-hover/main:-translate-y-full duration-300">
          <h2 className="font-bold sm:text-xl lg:text-2xl">{ProjectName}</h2>
          <h3 className="pt-2 text-xs sm:text-sm font-bold text-cyan-400">
            {ProjectTools}
          </h3>
          <p className="pt-0 sm:pt-2 text-xs lg:text-sm ">
            {ProjectDescription}
          </p>
          {ProjectDisclaimer && (
            <p className="pt-0 sm:pt-2 font-bold text-sm hidden sm:inline-block">
              {ProjectDisclaimer}
            </p>
          )}

          <div className="pt-2 space-x-4 flex">
            {ProjectLink && (
              <a
                aria-label="ProjectLink"
                target="_blank"
                href={ProjectLink}
                rel="noreferrer"
                className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
              >
                <FontAwesomeIcon
                  icon={faLink}
                  className="group-hover/button:rotate-[135deg] duration-500"
                />
              </a>
            )}
            {ProjectGithub && (
              <a
                aria-label="ProjectGithub"
                target="_blank"
                href=""
                rel="noreferrer"
                className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
              >
                <FontAwesomeIcon
                  icon={faGithub}
                  className="group-hover/button:rotate-[135deg] duration-500"
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
