import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import image1 from "../images/Kami2.jpg";
import cv from "../cv/V4.1 Kamil_M_Cwigun_CV.pdf";
import SquareLink from "../UI/squareLink";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useScrollBlock } from "../hooks/useScrollBlock.tsx";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Hero = ({ darkMode, setDarkMode }) => {
  const [open, setOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    open ? blockScroll() : allowScroll();
  }, [open, allowScroll, blockScroll]);
  return (
    <div className="h-screen flex flex-col justify-center items-center relative">
      <NavBar
        setOpen={setOpen}
        open={open}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <div className="-z-10 bg-cyan-400 h-1/2 w-1/3 absolute top-0 left-0" />
      <div className="z-20 bg-orange-600 h-1/2 w-1/3 absolute bottom-0 left-0" />
      <div className="-z-10 bg-zinc-900 h-full w-2/3 absolute bottom-0 right-0 " />
      <div className="-z-10 bg-zinc-200 h-full w-2/3 absolute bottom-0 right-0 translate-y-0 dark:-translate-y-full duration-1000" />

      <div
        className={`z-10 bg-black h-20 w-2/3 absolute bottom-0 right-0 text-white flex items-center justify-evenly -translate-x-full dark:translate-x-0 ${
          open && "!-bottom-20"
        } duration-1000`}
      >
        <a
          aria-label="Email"
          target="_blank"
          href="mailto:kamiwgun@googlemail.com"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="Github"
          target="_blank"
          href="https://github.com/Kami2Kc"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faGithub}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="LinkedIn"
          target="_blank"
          href="https://www.linkedin.com/in/kamil-cwigun-b19470240/"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="Discord"
          target="_blank"
          href="https://discordapp.com/users/171017679136751617"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faDiscord}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="Instagram"
          target="_blank"
          href="https://www.instagram.com/kami_2kc/"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="Twitter"
          target="_blank"
          href="https://twitter.com/Kami_2K"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faTwitter}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>
      </div>

      <div
        className={`z-10 bg-white h-20 w-2/3 absolute bottom-0 right-0 text-black flex items-center justify-evenly dark:translate-x-full ${
          open && "!-bottom-20"
        } duration-1000`}
      >
        <a
          aria-label="Email"
          target="_blank"
          href="mailto:kamiwgun@googlemail.com"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 border-black hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="Github"
          target="_blank"
          href="https://github.com/Kami2Kc"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 border-black hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faGithub}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="LinkedIn"
          target="_blank"
          href="https://www.linkedin.com/in/kamil-cwigun-b19470240/"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 border-black hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="Discord"
          target="_blank"
          href="https://discordapp.com/users/171017679136751617"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 border-black hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faDiscord}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="Instagram"
          target="_blank"
          href="https://www.instagram.com/kami_2kc/"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 border-black hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>

        <a
          aria-label="Twitter"
          target="_blank"
          href="https://twitter.com/Kami_2K"
          rel="noreferrer"
          className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 border-black hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
        >
          <FontAwesomeIcon
            icon={faTwitter}
            className="group-hover/button:rotate-[135deg] duration-500"
          />
        </a>
      </div>

      <div
        className={`z-30 duration-500 mx-8 bg-white overflow-hidden relative ${
          open && "-translate-x-1/2"
        }`}
      >
        <div className="absolute inset-0 bg-black -translate-y-full dark:translate-y-0 duration-1000 "/>
        <div className="relative flex flex-col-reverse sm:flex-row justify-center items-center overflow-hidden max-w-7xl">
          <div className="absolute aspect-square w-full h-full top-0 sm:w-1/3 sm:left-0 opacity-25 sm:opacity-100 overflow-hidden">
            <img
              src={image1}
              alt={"Kamil Cwigun"}
              className="object-cover h-full w-full scale-105"
            />
          </div>
          <img
            src={image1}
            alt={"Kamil Cwigun"}
            className="hidden sm:inline sm:h-full sm:w-1/3 opacity-0"
          />
          <div className="m-4 sm:m-8 flex flex-col space-y-4 dark:text-white text-sm xl:text-base z-0 duration-500">
            <h2 className="text-3xl xl:text-4xl font-bold">So, who am I ?</h2>
            <p className="text-sm sm:text-base">
              I am a highly motivated computer science graduate with a strong
              foundation in programming, algorithms, and software engineering.
              Throughout my academic journey, I have gained experience working
              on various software projects, both individually and in teams. I
              enjoy collaborating with other developers and stakeholders to
              create high-quality software that meets user needs.
            </p>
            <p className="text-sm sm:text-base">
              In addition to my technical skills, I am an excellent communicator
              with strong interpersonal skills. I am always eager to learn from
              people from diverse backgrounds and enjoy engaging with others. I
              am a lifelong learner and committed to staying up-to-date with the
              latest trends and technologies in the field of computer science.
            </p>
            <p className="text-sm sm:text-base">
              Overall, I am a dedicated and talented computer science graduate
              with a strong work ethic and a passion for creating innovative
              software solutions. I am excited to use my skills and knowledge to
              make a positive impact in the technology industry.
            </p>

            <SquareLink Icon={faFileLines} Link={cv} LinkDescription={"CV"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
