import React, { useEffect, useState } from "react";
import { useScrollBlock } from "../hooks/useScrollBlock.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const Module = ({
  ModuleName,
  ModuleTech,
  ModuleDescriptionP1,
  ModuleDescriptionP2,
  ModuleDescriptionP3,
  ModuleImage,
  ModuleLink,
}) => {
  const [open, setOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    open ? blockScroll() : allowScroll();
  }, [open, allowScroll, blockScroll]);
  return (
    <>
      <a
        href="#Modules"
        className={`group h-[150px] w-[150px] sm:h-[200px] sm:w-[200px] md:h-[300px] md:w-[300px] xl:h-[400px] xl:w-[400px] relative overflow-hidden hover:scale-105 duration-500`}
        onClick={() => {
          setOpen(true);
        }}
      >
        <div
          className={`h-[150px] w-[150px] sm:h-[200px] sm:w-[200px] md:h-[300px] md:w-[300px] xl:h-[400px] xl:w-[400px]`}
        >
          <img
            src={ModuleImage}
            alt={"Module"}
            className={`object-cover absolute h-full w-full inset-0 scale-110 group-hover:blur-sm group-hover:scale-100 duration-500`}
          />
          <div
            className={`relative bg-zinc-800 h-full w-full bg-opacity-0 group-hover:bg-opacity-25 duration-500 z-10`}
          >
            <div
              className={`p-2 md:p-8 text-white text-left flex flex-col justify-center space-y-1 absolute inset-0 translate-y-full group-hover:translate-y-0 duration-500`}
            >
              <h2 className="md:text-xl font-bold">{ModuleName}</h2>
              <h3 className="text-sm md:text-base font-semibold text-cyan-400">
                {ModuleTech}
              </h3>
              <p className="hidden md:inline text-sm">{ModuleDescriptionP1}</p>
            </div>
          </div>
        </div>
      </a>

      <div
        className={`absolute bg-zinc-800 backdrop-blur-sm -inset-[1000px] bg-opacity-25 opacity-0 transition-opacity duration-500 ${
          !open && "hidden"
        } ${open && "opacity-100 z-10"}`}
        onClick={() => {
          setOpen(false);
        }}
      />

      <div
        className={`absolute flex z-20 bg-zinc-100 dark:bg-zinc-900 top-36 left-0 right-0  opacity-0 transition-opacity duration-500 max-w-7xl mx-auto ${
          !open && "hidden"
        } ${open && "opacity-100"}`}
      >
        <img
          src={ModuleImage}
          className="absolute h-full w-full sm:w-1/3 object-cover opacity-25 sm:opacity-100"
        />

        <img
          src={ModuleImage}
          className="hidden sm:inline h-full w-1/3 opacity-0"
        />

        <div className="m-8 h-full w-2/3 flex flex-col space-y-4 dark:text-white z-10">
          <h2 className="text-2xl font-bold">{ModuleName}</h2>
          <h3 className="text-lg font-semibold text-cyan-400">{ModuleTech}</h3>
          <p className="">{ModuleDescriptionP1}</p>
          {ModuleDescriptionP2 && <p className="">{ModuleDescriptionP2}</p>}
          {ModuleDescriptionP3 && <p className="">{ModuleDescriptionP3}</p>}
          {ModuleLink && (
            <a
              aria-label="ProjectGithub"
              target="_blank"
              href={ModuleLink}
              rel="noreferrer"
              className="flex items-center justify-center group/button h-8 w-8 text-lg border-2 border-black dark:border-white hover:border-transparent dark:hover:border-transparent hover:bg-orange-600 hover:rotate-[225deg] duration-500 select-none"
            >
              <FontAwesomeIcon
                icon={faGithub}
                className="group-hover/button:rotate-[135deg] duration-500"
              />
            </a>
          )}
        </div>

        <button
          className={`absolute right-4 top-4 h-8 w-8 rotate-[225deg] overflow-show hover:scale-110 duration-100`}
          onClick={() => {
            setOpen(false);
          }}
        >
          <div
            className={`relative flex flex-col justify-center items-center rotate-[135deg] duration-500 delay-700`}
          >
            <div
              className={`absolute h-1 w-6 bg-black dark:bg-white rotate-45 duration-300 delay-700`}
            />
            <div
              className={`absolute h-1 w-6 bg-black dark:bg-white -rotate-45 duration-300 delay-700`}
            />
          </div>
        </button>
      </div>
    </>
  );
};

export default Module;
