import React, { useState, useEffect } from "react";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import Modules from "./components/Modules";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Skills from "./components/Skills";

function App() {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    if (darkMode == true) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  });
  return (
    <>
      <div className="bg-fixed relative overflow-hidden">
        {/* <div className="fixed inset-0 bg-zinc-900 opacity-0 dark:opacity-60 -z-20 duration-500" /> */}
        <div className="fixed inset-0 bg-zinc-200 dark:bg-zinc-900 -z-30 duration-500" />

        <Hero darkMode={darkMode} setDarkMode={setDarkMode} />
        <Modules />
        <Projects />
        <Skills />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default App;
