import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

const Skill = ({ Name }) => {
  return (
    <div className="px-4 h-12 w-[150px] md:w-[200px] lg:w-[400px] bg-white dark:bg-black dark:text-white md:text-2xl flex items-center gap-2 duration-500">
      <FontAwesomeIcon className="text-green-500" icon={faCircleCheck} />
      <p>{Name}</p>
    </div>
  );
};

export default Skill;
