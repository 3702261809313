import React from "react";
import theLowdown from "../images/the-lowdown-project.png";
import howlingHuskies from "../images/HHNFT.png";
import momentumBoost from "../images/MomentumBoost.png";
import misial from "../images/photographerPortfolio.png";
import movieSearch from "../images/MovieSearch.png";
import minecraft from "../images/minecraft.png";
import Project from "./Project";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";

const Projects = () => {
  return (
    <div id="Projects" className="m-8 flex flex-col items-center ">
      <div className="flex flex-col items-center max-w-2xl m-8">
        <FontAwesomeIcon icon={faCode} className="text-5xl text-cyan-400" />
        <h2 className="dark:text-white text-center text-5xl my-4 font-semibold">
          Personal Projects
        </h2>
        <p className="dark:text-gray-400 font-semibold text-center">
          Here we have some of the personal projects that I have worked on
          during my free time. These include solo and group projects that helped
          me learn new skills, and some are just to make my life a bit easier.
        </p>
      </div>

      <div className=" grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Project
          ProjectName={"THE-LOWDOWN.COM PROJECT"}
          ProjectTools={"React, Tailwind CSS, JavaScript, CSS"}
          ProjectImage={theLowdown}
          ProjectDescription={
            "I am currently collaborating on a project website aimed at improving our skills in front-end web development. We've chosen THE-LOWDOWN.COM as a challenging model to replicate while also adding our own unique touches. Our goal is to closely mirror the website's design while also making it distinctive.!"
          }
          ProjectDisclaimer={""}
          ProjectImageDescription={"THE-LOWDOWN.COM PROJECT"}
          ProjectLink={"https://the-lowdown-project.vercel.app/"}
          ProjectGithub={""}
        />

        <Project
          ProjectName={"Movie Search"}
          ProjectTools={"React, Tailwind CSS, JavaScript, CSS"}
          ProjectImage={movieSearch}
          ProjectDescription={
            "A quick movie search React App I made while learning JavaScript. I used the OMDb API to search for movies and display details such as release date, director, IMDb rating and more."
          }
          ProjectDisclaimer={""}
          ProjectImageDescription={"Movie Search"}
          ProjectLink={"https://movie-search-bay-two.vercel.app/"}
          ProjectGithub={""}
        />

        <Project
          ProjectName={"Momentum Boost"}
          ProjectTools={"HTML, CSS, PHP, JavaScript, WordPress"}
          ProjectImage={momentumBoost}
          ProjectDescription={
            "I joined the Momentum Boost team as their web developer to troubleshoot and add missing features to their website. At the time, the team didn't have an active web developer, which meant I had to work independently to understand the platform and its functionality. After some time, I was able to identify and fix common bugs and complete some unfinished features."
          }
          ProjectImageDescription={"Momentum Boost"}
          ProjectLink={""}
          ProjectGithub={""}
        />

        <Project
          ProjectName={"Minecraft AFK Fishing Script"}
          ProjectTools={"Python, PyAudio"}
          ProjectImage={minecraft}
          ProjectDescription={
            "During my free time playing Minecraft, I found myself pondering how I could simplify the task of fishing. This led me to develop a Python script that utilizes PyAudio to automate the process for me. The script analyzes the audio input and calculates the volume, triggering a double click of the right mouse button to reel in the fish as soon as the threshold is met or exceeded. Once the fish is caught, the script then casts the fishing line back in the water to catch another one."
          }
          ProjectDisclaimer={""}
          ProjectImageDescription={"Minecraft AFK Fishing"}
          ProjectLink={"https://github.com/Kami2Kc/Minecraft-AFK-Fishing"}
          ProjectGithub={""}
        />

        <Project
          ProjectName={"Howling Huskies NFT"}
          ProjectTools={"React, JavaScript, CSS"}
          ProjectImage={howlingHuskies}
          ProjectDescription={
            "Howling Huskies NFT was a blockchain experiment that served as a valuable learning experience for me. The project involved experimenting with both Solana and Ethereum technologies. Despite our efforts to grow the community, the project did not gain traction. Nonetheless, the experience provided me with valuable insights and was a fun collaborative effort with friends."
          }
          ProjectImageDescription={"Howling Huskies NFT"}
          ProjectLink={"https://nft-minting-dapp-main-test-master.vercel.app/"}
          ProjectGithub={""}
        />

        <Project
          ProjectName={"Photographer Portfolio"}
          ProjectTools={"React, Tailwind CSS, JavaScript, CSS"}
          ProjectImage={misial}
          ProjectDescription={
            "I'm in the process of creating a portfolio website for a photographer. The website will showcase their work and provide links to their social media accounts. Additionally, it will serve as a blog where the photographer can share information about their equipment and recommend interesting locations. I'm currently in the early stages of development and am learning React while juggling other priorities."
          }
          ProjectImageDescription={"Misial Photography"}
          ProjectLink={""}
          ProjectGithub={""}
        />
      </div>
    </div>
  );
};

export default Projects;
