import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

const Contact = () => {
  const form = useRef();
  const NameField = useRef();
  const EmailField = useRef();
  const MessageField = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_gr21t4w",
      "template_otbr12j",
      form.current,
      "NLph1QP93Jl1rRkWz"
    );
    NameField.current.value = "";
    EmailField.current.value = "";
    MessageField.current.value = "";
  };

  return (
    <div id="Contact" className="mx-8 flex flex-col items-center">
      <div className="flex flex-col items-center max-w-2xl m-8">
        <FontAwesomeIcon
          icon={faPenToSquare}
          className="text-5xl text-cyan-400"
        />
        <h2 className="dark:text-white text-center text-5xl my-4 font-semibold">
          Contact
        </h2>
        <p className="dark:text-gray-400 font-semibold text-center">
          Here you can get in contact with me by filling out the form below, You can also contact me in other ways like my email directly, mobile, or any other way using the links in the footer. I'm located in the Uxbridge area which means that I can easily travel to and from London as well as areas outside of the M25.
        </p>
      </div>

      <div className="mb-8 drop-shadow-lg overflow-hidden flex flex-col-reverse sm:flex-row w-full max-w-6xl">
        <div className="relative top-0 bottom-0 left-0 right-0 flex-col items-center justify-center text-center w-full sm:w-2/3 hidden sm:inline">
          <iframe
            title="map"
            className="h-full w-full"
            src="https://www.google.com/maps/embed/v1/place?q=Uxbridge,+UK&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          />
          <div className="bg-white dark:bg-black h-40 w-60 absolute bottom-4 left-4 flex flex-col text-left p-4 font-semibold space-y-4 border-2 border-black dark:border-white">
            <div>
              <p className="dark:text-white"> Email</p>
              <a
                aria-label="Email"
                target="_blank"
                href="mailto:kamiwgun@googlemail.com"
                rel="noreferrer"
                className="dark:text-gray-400 hover:text-orange-600 dark:hover:text-orange-600 duration-500"
              >
                kamiwgun@googlemail.com
              </a>
            </div>
            <div>
              <p className="dark:text-white"> Mobile</p>
              <p className="dark:text-gray-400"> 07715441658</p>
            </div>
          </div>
        </div>

        <form
          ref={form}
          onSubmit={sendEmail}
          className="p-4 top-0 bottom-0 left-1/2 right-0 flex flex-col w-full sm:w-1/3 bg-white dark:bg-black dark:text-white"
        >
          <label className='mb-2 text-2xl after:content-["*"] after:text-red-600 after:ml-0'>
            Name
          </label>
          <input
            required
            ref={NameField}
            type="text"
            name="user_name"
            className="p-2 mb-8 placeholder:italic placeholder:text-gray-500 bg-transparent outline outline-2 dark:outline-white dark:hover:outline-gray-400 focus:outline-orange-600 dark:focus:outline-orange-600 focus:bg-zinc-400 dark:focus:bg-zinc-800"
            placeholder="Name"
          ></input>
          <label className='mb-2 text-2xl after:content-["*"] after:text-red-600 after:ml-0'>
            E-mail
          </label>
          <input
            required
            ref={EmailField}
            type="email"
            name="user_email"
            className="p-2 mb-8 placeholder:italic placeholder:text-gray-500 bg-transparent outline outline-2 dark:outline-white dark:hover:outline-gray-400 focus:outline-orange-600 dark:focus:outline-orange-600 focus:bg-zinc-400 dark:focus:bg-zinc-800"
            placeholder="E-mail"
          ></input>
          <label className='mb-2 text-2xl after:content-["*"] after:text-red-600 after:ml-0'>
            Message
          </label>
          <textarea
            required
            ref={MessageField}
            name="message"
            className="p-2 mb-8 h-48 placeholder:italic placeholder:text-gray-500 bg-transparent outline outline-2 dark:outline-white dark:hover:outline-gray-400 focus:outline-orange-600 dark:focus:outline-orange-600 focus:bg-zinc-400 dark:focus:bg-zinc-800"
            placeholder="Message me anything."
          ></textarea>
          <button
            type="submit"
            value="Send"
            className="p-1 font-semibold select-none bg-cyan-400 hover:bg-orange-600 duration-300 transition-colors hover:border-transparent before:content-['Submit'] hover:before:content-['Send_it_off']"
          ></button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
